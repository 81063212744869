export enum AppRoute {
  Home = "/",
  Login = "/login",
  Personal = "/personal",
  Email = "/email",
  Stats = "/statistics",
  Clubs = "/clubs",
  RemindPassword = "/remindpassword",
  UpdatePassword = "/updatepassword",
  QRcode = "/qrcode",
  ChartPace = "/chartpace",
  ChartVolunteering = "/chartvolunteering",
  PrintQRcode = "/print",
  SetPassword = "/p/:hash",
  RzdBonusId = "/rzdbonusid",
  SportmasterBonus = "/sportmasterbonus",
}

export enum AuthorizationStatus {
  Auth = "AUTH",
  NoAuth = "NO_AUTH",
  Unknown = "UNKNOWN",
}

export enum APIRoute {
  Login = "/account/login",
  Athlete = "/account/athlete/get",
  UpdateAthlete = "/account/athlete/update",
  Events = "/account/event/list",
  RequestPasswordChange = "/account/password/change/init",
  SetNewPassword = "/account/password/change/submit",
  Stats = "/website/athlete/statById",
  UpdateCurrentPassword = "/account/athlete/password/update",
  PrintQRCode = "/account/athlete/qrcode",
  GetRzdBonusId = "/account/rzdBonusId/get",
  SetRzdBonusId = "/account/rzdBonusId/set",
  DeleteRzdBonusId = "/account/rzdBonusId/delete",
  JoinBonusProgram = "/account/bonus/join",
  UpdateSMBonusProgram = "/account/bonus/update/sportmaster",
}

export enum ErrorCodes {
  AccessDenied = 10004,
  WrongLoginPassword = 10005,
  AthleteNotFound = 10006,
  BadIDRequest = -1,
  InternalSystemError = 99999,
  WrongOldPassword = 17100,
  NetworkError = "ERR_NETWORK",
  RzdBonusIdNotRegistered = 404,
  FormSendError = 400,
  ServerError = 500,
  DuplicatePhoneNumber = 10202,
  DuplicateRzdBonusID = 10200,
}

export enum ErrorMessages {
  NotRegistredRzdBonusId = "Request failed with status code 404",
}

export enum KeyboardCodes {
  Backspace = 'Backspace',
}

export enum WarningMessage {
  AuthFail = "Не забудьте авторизоваться",
  WrongPassword = "Неверный логин/пароль",
  AccessDenied = "Доступ запрещен. Обратитесь в службу поддержки мероприятий: eventsupport@5verst.ru",
  SigninFail = "Ошибка авторизации",
  NetworkError = "Ошибка сети",
  AuthRequired = "Необходимо авторизоваться",
  SaveSuccess = "Данные сохранены",
  PasswordRequestSuccess = "Вам отправлена ссылка для смены пароля ",
  PasswordSetSuccess = "Пароль успешно изменен",
  AthleteNotFound = "Участник с таким email не найден",
  BadIDRequest = "Ошибка при поиске ID",
  LinkIsEpired = "Ссылка на смену пароля просрочена",
  InternalSystemError = "Произошла внутренняя ошибка системы",
  PasswordsNotIdentical = "Новые пароли не совпадают",
  WrongOldPassword = "Неверный старый пароль",
  NotCorrectID = "Введён некорректный 5 вёрст ID",
  NotEmptyInput = "Поля со звёздочкой не могут быть пустыми",
  NotLetterInput = "Поля со свездочкой должны иметь хотя бы одну букву",
  WrongNameFormat = "Неверный формат полей \"ФИО\", \"Контактное лицо\"",
  WrongPhoneFormat = "Неверный формат поля \"Телефон контактного лица\"",
  RzdBonusIdSuccess ="«РЖД Бонус ID» принят",
  RzdBonusIdWrongFormat = "РЖД Бонус ID» должен начинаться с 9002 и иметь 13 цифр",
  DuplicateRzdBonusIdError = "Введённый РЖД Бонус указан другим участником. Обратитесь в support@5verst.ru",
  JoinSportmasterProgramSuccess = "Ваш телефон добавлен",
  UpdateSportmasterProgramSuccess = "Вы отказались от участия в бонусной программе",
  DuplicatePhoneNumberError = "Введённый номер телефона указан другим участником. Обратитесь в support@5verst.ru"
}

export enum RzdBonusStatus {
  Pending = "pending",
  Processing = "processing",
  Declined = "declined",
  Accepted = "accepted",
}

export const { REACT_APP_SITE_URL } = process.env;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export enum ExternalLink {
  ParkrunnerUrl = 'https://www.parkrun.org.uk/parkrunner/',
  ShopCardUrl = "https://shop.5verst.ru/?utm_source=lk&utm_medium=button&utm_campaign=regular",
  VerstSiteUrl = "https://5verst.ru/",
  RemindIDUrl = "https://5verst.ru/reminder/",
  UserStatsUrl = "https://5verst.ru/userstats/",
  SportmasteClubrUrl = "https://www.sportmaster.ru/clubpro/rules/"
}

export enum MainColors {
  Yellow = '#de9636',
  Red = '#e6564c',
  LightGreen = '#acd2b8',
  Blue = '#2b326d',
}

export enum SecondaryColors {
  Raspberry = '#da3166',
  DarkGreen = '#5a624e',
  SecondaryBlue = '#6e84be',
  DarkBlue = '#1c1d44',
}

export const paceDataToShow = 50;

export const EventCounts = [10, 25, 50, 100, 250, 500];

export const ClubRuns = ["run10", "run25", "run50", "run100", "run250", "run500"];

export const ClubVolunteers = ["vol10", "vol25", "vol50", "vol100", "vol250", "vol500"];

export const minClubMembershipIndex = 0;

export enum ClubRunFlags {
  TenRuns = "run10",
  TwentyFiveRuns = "run25",
  FiftyRuns = "run50",
  OneHundredRuns = "run100",
  TwoHundredFiftyRuns = "run250",
  FiveHundredRuns = "run500",
}

export enum ClubVolunteeringFlags {
  TenVolunteerings = "vol10",
  TwentyFiveVolunteerings = "vol25",
  FiftyVolunteerings = "vol50",
  OneHundredVolunteerings = "vol100",
  TwoHundredFiftyVolunteerings = "vol250",
  FiveHundredVolunteerings = "vol500",
}

export enum ClubIconSize {
  Width = '30',
  Height = '30',
} 

export const Banners = [
  [
    {
      id: '1',
      name: 'card-1',
      src_desktop: 'img/banners/shop-ang-forever/desktop/card1-desktop.png',
      src_tablet: 'img/banners/shop-ang-forever/tablet/card1-tablet.png',
      src_mobile: 'img/banners/shop-ang-forever/mobile/card1-mobile.png',
      url: 'https://shop.5verst.ru/product-category/karty/?utm_source=lk&utm_medium=button&utm_campaign=cards-1',
      alt: 'Рекламный баннер карточек из магазина 5 вёрст'
    },
    {
      id: '2',
      name: 'card-3',
      src_desktop: 'img/banners/shop-ang-forever/desktop/card3-desktop.png',
      src_tablet: 'img/banners/shop-ang-forever/tablet/card3-tablet.png',
      src_mobile: 'img/banners/shop-ang-forever/mobile/card3-mobile.png',
      url: 'https://shop.5verst.ru/product-category/karty/?utm_source=lk&utm_medium=button&utm_campaign=cards-3',
      alt: 'Рекламный баннер карточек из магазина 5 вёрст'
    },
  ],
  [
    {
      id: '3',
      name: 't-shirt-2',
      src_desktop: 'img/banners/shop-ang-forever/desktop/t-shirt2-desktop.png',
      src_tablet: 'img/banners/shop-ang-forever/tablet/t-shirt2-tablet.png',
      src_mobile: 'img/banners/shop-ang-forever/mobile/t-shirt2-mobile.png',
      url: 'https://shop.5verst.ru/?utm_source=lk&utm_medium=button&utm_campaign=t-shirt-2',
      alt: 'Рекламный баннер футболок из магазина 5 вёрст'
    },
    {
      id: '4',
      name: 't-shirt-3',
      src_desktop: 'img/banners/shop-ang-forever/desktop/t-shirt3-desktop.png',
      src_tablet: 'img/banners/shop-ang-forever/tablet/t-shirt3-tablet.png',
      src_mobile: 'img/banners/shop-ang-forever/mobile/t-shirt3-mobile.png',
      url: 'https://shop.5verst.ru/?utm_source=lk&utm_medium=button&utm_campaign=t-shirt-3',
      alt: 'Рекламный баннер футболок из магазина 5 вёрст'
    },
  ],
  [
    {
      id: '5',
      name: 'forever-1',
      src_desktop: 'img/banners/shop-ang-forever/desktop/forever1-desktop.png',
      src_tablet: 'img/banners/shop-ang-forever/tablet/forever1-tablet.png',
      src_mobile: 'img/banners/shop-ang-forever/mobile/forever1-mobile.png',
      url: 'https://5verst.ru/forever/?utm_source=lk&utm_medium=button&utm_campaign=forever-1',
      alt: 'Баннер сайта пожертвований 5 вёрст'
    },
    {
      id: '6',
      name: 'forever-1',
      src_desktop: 'img/banners/shop-ang-forever/desktop/forever2-desktop.png',
      src_tablet: 'img/banners/shop-ang-forever/tablet/forever2-tablet.png',
      src_mobile: 'img/banners/shop-ang-forever/mobile/forever2-mobile.png',
      url: 'https://5verst.ru/forever/?utm_source=lk&utm_medium=button&utm_campaign=forever-2',
      alt: 'Баннер сайта пожертвований 5 вёрст'
    },
  ],
  [
    {
      id: '7',
      name: 'omy-1',
      src_desktop: 'img/banners/omy/omy-desktop.jpg',
      src_tablet: 'img/banners/omy/omy-tablet.jpg',
      src_mobile: 'img/banners/omy/omy-mobile.jpg',
      url: 'https://5verst.ru/partners/o-my-sports/?utm_source=lk&utm_medium=omy&utm_campaign=regular25',
      alt: 'Баннер приложения OMY!Sports'
    },
  ],
];

//export const REACT_APP_BACKEND_URL = "https://nrms-dev.5verst.ru/api/v1";

export enum Partners {
  Sportmaster = 'sportmaster',
}

//at least 1 digit, 1 lowcase, 1 capital and not shorter then 8 symbols
export const passwordRegEx = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
